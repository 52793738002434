<template>
  <div>
      <b-modal :id="id" centered hide-footer @close="setCookie(false)" class="modalWindow">
        <template modal-title>
          <h2>{{ title }}</h2>
        </template>
        <p class="my-4">{{ modalText }}</p>
        <b-button class="mt-3 success" block @click="setCookie(true)" variant="primary">{{ buttonText }}</b-button>
      </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  mounted () {
    if (this.$cookies.get(this.cookieName) == null) {
      setTimeout(() => {
        this.$bvModal.show(this.id)
      }, this.delayInSeconds * 1000)
    }
  },
  data () {
    return {
      show: false
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    modalText: {
      type: String,
      default: ''
    },
    cookieName: {
      type: String,
      default: ''
    },
    cookieLifetime: {
      type: String,
      default: '30d'
    },
    delayInSeconds: {
      type: Number,
      default: 4
    },
    url: {
      type: String,
      default: ''
    }
  },
  methods: {
    setCookie (callUrl) {
      const cookieSet = this.$cookies.get(this.cookieName)
      if (cookieSet != null) {
        console.log(this.cookieName + ' cookie exists')
      } else {
        this.$cookies.set(this.cookieName, 'clicked', this.cookieLifetime)
        console.log(this.cookieName + ' cookie created')
      }
      if (callUrl) {
        console.log('call url ' + this.url)
        window.location.href = this.url
      }
    }
  }
}
</script>

<style lang="scss">
.modalWindow {}
</style>
